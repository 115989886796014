import React, { useState } from "react"
import {navigate} from "gatsby"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { SnackbarProvider } from "notistack"

import {
  Button,
  makeStyles,
  Grid,
  TextField,
  FormControl,
  ButtonGroup,
  Snackbar,
  CircularProgress,
  Typography,
  Container,
  Box,
} from "@material-ui/core"

import SEO from "../components/seo"
import Sigin from "../components/signin"
import MuiAlert from "@material-ui/lab/Alert"
import Breadcrumbs from "../components/breadcrumbs"

import "../components/layout.css"

const windowGlobal = typeof window !== "undefined" && window

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainContainer: {
    marginTop: "3.5rem",
    marginBottom: "3.5rem",
  },
  formConrolSignup: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& input": {
      padding: "10px 12px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
  orderBookBtn: {
    height: 40,
    borderRadius: 10,
  },
  textCenter: {
    textAlign: "center",
    "& > .MuiTypography-root": {
      marginBottom: theme.spacing(2),
      fontSize: 26,
    },
  },
  notSignedIn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: "26vh",
    "& .MuiButtonBase-root": {
      backgroundColor: "#003e96",
      padding: "6px 12px",
      borderRadius: 10,
    },
  },
  loggedin: {
    display: "block !important",
  },
  haveToLogin: {
    position: "absolute",
    overflow: "hidden",
    clip: "rect(0 0 0 0)",
    height: 1,
    width: 1,
    margin: "-1px",
    padding: 0,
    border: 0,
  },
}))

const OrderBook = () => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
  const phoneRegexd = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-/0-9]*$/

  const [orderBookFormValues, setOrderBookFormValues] = useState({
    firstName: "",
    lastName: "",
    authorName: "",
    bookName: "",
    inspectorName: "",
    publisherName: "",
    yourNotes: "",
    telephone: "",
    country: "",
    userEmail: "",
    contactEmail: "string",
    contactCountry: "string",
  })

  const [errorMassege, setErrorMassege] = useState({
    password: false,
    passwordText: "",
    email: false,
    emailText: "",
    autorName: false,
    autorNameText: "",
    bookName: false,
    bookNameText: "",
    inspectorName: false,
    inspectorNameText: "",
    publisherName: false,
    publisherNameText: "",
    yourNotes: false,
    yourNotesText: "",
    telephone: false,
    telephoneText: "",
  })

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [openAlertError, setOpenAlertError] = useState(false)
  const [openAlertErrorOnSubmit, setOpenAlertErrorOnSubmit] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMassegeCatch, setErrorMassegeCatch] = useState("")

  let jwtForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  }

  const handleChange = prop => event => {
    if (isSubmitted) {
      enterdTelephone()
      enterdTelephoneLength()
      enterdEmail()
    }
    setOrderBookFormValues({
      ...orderBookFormValues,
      [prop]: event.target.value,
    })
  }

  const handleClose = reason => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccess(false)
    setOpenAlertError(false)
    setOpenAlertErrorOnSubmit(false)
  }

  const breadcrumbs = [
    {
      text: t("header.orderBookPage"),
    },
  ]

  const enterdEmail = () => {
    if (emailRegExp.test(orderBookFormValues.userEmail)) {
      setErrorMassege({ ...errorMassege, email: false, emailText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        email: true,
        emailText: t("errors.invalidEmailAddress"),
      })
      return false
    }
  }

  const enterdTelephone = () => {
    if (phoneRegexd.test(orderBookFormValues.telephone)) {
      setErrorMassege({ ...errorMassege, telephone: false, telephoneText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        telephone: true,
        telephoneText: t("errors.invalidPhoneNumber"),
      })
      return false
    }
  }
  const enterdTelephoneLength = () => {
    if (orderBookFormValues.telephone.length >= 9) {
      setErrorMassege({ ...errorMassege, telephone: false, telephoneText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        telephone: true,
        telephoneText: t("errors.numberLegnth"),
      })
      return false
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setIsSubmitted(true)

    if (enterdEmail() && enterdTelephone() && enterdTelephoneLength()) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/requested-books`,
          {
            notes: orderBookFormValues.yourNotes,
            bookName: orderBookFormValues.bookName,
            publisher: orderBookFormValues.publisherName,
            authorName: orderBookFormValues.authorName,
            contactName: orderBookFormValues.firstName,
            contactEmail: orderBookFormValues.userEmail,
            contactPhone: orderBookFormValues.telephone,
            contactCountry: orderBookFormValues.country,
            contactLastName: orderBookFormValues.lastName,
            invistigatorName: orderBookFormValues.inspectorName,
          },
          {
            headers: { Authorization: `Bearer ${jwtForProfile}` },
            "Accept-Language": i18n.language,
          }
        )
        .then(() => {
          setLoading(false)
          setOpenAlertSuccess(true)
          setTimeout(() => {
            navigate(`/`)
          }, 1000);
        })
        .catch(err => {
          setLoading(false)
          setOpenAlertError(true)
          setDisabledBtn(true)
          setTimeout(() => setOpenAlertError(false), 2000)
          setErrorMassegeCatch(err.response.data.message)
        })
    } else {
      setLoading(false)
      setTimeout(() => setOpenAlertErrorOnSubmit(false), 2000)
      setOpenAlertErrorOnSubmit(true)
    }
  }

  return (
    <>
      <SEO title={t("orderBook.title")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      {/* {jwtForProfile && ( */}
      <Box component="div" display={jwtForProfile ? `block` : `none`}>
        <Container className={`${classes.mainContainer}`}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} className={classes.textCenter}>
                <Typography variant="subtitle1" component="h3">
                  {t("orderBook.bookDetails")}
                </Typography>
                <FormControl
                  fullWidth
                  className={`${classes.margin} ${classes.formConrolSignup}`}
                >
                  <TextField
                    required
                    id="bookName"
                    type="text"
                    label={t("orderBook.bookName")}
                    variant="outlined"
                    value={orderBookFormValues.bookName}
                    onChange={handleChange("bookName")}
                    error={errorMassege.bookName}
                    helperText={errorMassege.bookNameText}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  className={`${classes.margin} ${classes.formConrolSignup}`}
                >
                  <TextField
                    required
                    id="authorName"
                    type="text"
                    label={t("orderBook.authorName")}
                    variant="outlined"
                    value={orderBookFormValues.authorName}
                    onChange={handleChange("authorName")}
                    error={errorMassege.authorName}
                    helperText={errorMassege.authorNameText}
                  />
                </FormControl>
                <FormControl
                  className={`${classes.margin} ${classes.formConrolSignup}`}
                  fullWidth
                >
                  <TextField
                    required
                    id="inspectorName"
                    name="inspectorName"
                    label={t("orderBook.inspectorName")}
                    variant="outlined"
                    value={orderBookFormValues.inspectorName}
                    error={errorMassege.inspectorName}
                    helperText={errorMassege.inspectorNameText}
                    onChange={handleChange("inspectorName")}
                  />
                </FormControl>
                <FormControl
                  className={`${classes.margin} ${classes.formConrolSignup}`}
                  fullWidth
                >
                  <TextField
                    required
                    id="publisherName"
                    name="publisherName"
                    label={t("orderBook.publisherName")}
                    variant="outlined"
                    value={orderBookFormValues.publisherName}
                    error={errorMassege.publisherName}
                    helperText={errorMassege.publisherName}
                    onChange={handleChange("publisherName")}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  className={`${classes.margin} ${classes.formConrolSignup}`}
                >
                  <TextField
                    id="yourNotes"
                    multiline
                    rows={4}
                    label={t("orderBook.yourNotes")}
                    variant="outlined"
                    value={orderBookFormValues.yourNotes}
                    onChange={handleChange("yourNotes")}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} className={classes.textCenter}>
                <Typography variant="subtitle1" component="h3">
                  {t("orderBook.contactInfo")}
                </Typography>
                <FormControl
                  fullWidth
                  className={`${classes.margin} ${classes.formConrolSignup}`}
                >
                  <Grid
                    container
                    directoin="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="firstName"
                        type="text"
                        label={t("orderBook.firstName")}
                        variant="outlined"
                        value={orderBookFormValues.firstName}
                        onChange={handleChange("firstName")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="lastName"
                        type="text"
                        label={t("orderBook.lastName")}
                        variant="outlined"
                        value={orderBookFormValues.lastName}
                        onChange={handleChange("lastName")}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl
                  fullWidth
                  className={`${classes.margin} ${classes.formConrolSignup}`}
                >
                  <Grid
                    container
                    directoin="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="telephone"
                        type="tel"
                        label={t("orderBook.telephone")}
                        variant="outlined"
                        placeholder="00905123456789"
                        value={orderBookFormValues.telephone}
                        onChange={handleChange("telephone")}
                        error={errorMassege.telephone}
                        helperText={errorMassege.telephoneText}
                        inputProps={{ maxLength: 14 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="country"
                        type="text"
                        label={t("orderBook.country")}
                        variant="outlined"
                        value={orderBookFormValues.country}
                        onChange={handleChange("country")}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl
                  fullWidth
                  className={`${classes.margin} ${classes.formConrolSignup}`}
                >
                  <TextField
                    id="email"
                    type="text"
                    label={t("orderBook.email")}
                    variant="outlined"
                    value={orderBookFormValues.userEmail}
                    onChange={handleChange("userEmail")}
                    error={errorMassege.email}
                    helperText={errorMassege.emailText}
                  />
                </FormControl>
                <ButtonGroup
                  fullWidth={true}
                  color="primary"
                  aria-label="Order"
                  disabled={disabledBtn}
                  loading={loading.loading}
                  className={`${classes.button} ${classes.margin} button`}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.orderBookBtn}
                  >
                    {loading && <CircularProgress size={26} />}
                    {!loading && t("orderBook.orderBookBtn")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>

          <SnackbarProvider maxSnack={3}>
            <Snackbar
              open={openAlertSuccess}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="success">
                {t("orderBook.orderSent")}
              </Alert>
            </Snackbar>
            <Snackbar
              open={openAlertError}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {errorMassegeCatch}
              </Alert>
            </Snackbar>
            <Snackbar
              open={openAlertErrorOnSubmit}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {t("errors.fieldsWrong")}
              </Alert>
            </Snackbar>
          </SnackbarProvider>
        </Container>
      </Box>
      <Box component="div" display={!jwtForProfile ? `block` : `none`}>
        <Container className={`${classes.notSignedIn} `}>
          <Typography variant="subtitle1">
            {t("myProfile.noPremissions")}
          </Typography>
          <br />
          <Sigin />
        </Container>
      </Box>
    </>
  )
}
export default OrderBook
